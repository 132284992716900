// Generated by Framer (2263e31)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/HIXXmEnAC";

const cycleOrder = ["Hzyo0bXoM"];

const serializationHash = "framer-KdoLO"

const variantClassNames = {Hzyo0bXoM: "framer-v-1sdsj6h"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, BDYTAD09Z: title ?? props.BDYTAD09Z ?? "Home", U8cqiXnj0: link ?? props.U8cqiXnj0} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, BDYTAD09Z, U8cqiXnj0, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Hzyo0bXoM", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-KdoLO", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1sdsj6h", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Hzyo0bXoM"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UG9wcGlucy1yZWd1bGFy", "--framer-font-family": "\"Poppins\", \"Poppins Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}><Link href={U8cqiXnj0} openInNewTab={false} smoothScroll={false}><motion.a className={"framer-styles-preset-1xna0sg"} data-styles-preset={"HIXXmEnAC"}>Home</motion.a></Link></motion.p></React.Fragment>} className={"framer-wd6gwl"} fonts={["GF;Poppins-regular"]} layoutDependency={layoutDependency} layoutId={"KExCoqJDD"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={BDYTAD09Z} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-KdoLO [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KdoLO .framer-1jx7ybf { display: block; }", ".framer-KdoLO .framer-1sdsj6h { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-KdoLO .framer-wd6gwl { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-KdoLO .framer-1sdsj6h { gap: 0px; } .framer-KdoLO .framer-1sdsj6h > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-KdoLO .framer-1sdsj6h > :first-child { margin-top: 0px; } .framer-KdoLO .framer-1sdsj6h > :last-child { margin-bottom: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 19
 * @framerIntrinsicWidth 48
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"BDYTAD09Z":"title","U8cqiXnj0":"link"}
 * @framerImmutableVariables true
 */
const FramerzxrtH8En5: React.ComponentType<Props> = withCSS(Component, css, "framer-KdoLO") as typeof Component;
export default FramerzxrtH8En5;

FramerzxrtH8En5.displayName = "Nav Link";

FramerzxrtH8En5.defaultProps = {height: 19, width: 48};

addPropertyControls(FramerzxrtH8En5, {BDYTAD09Z: {defaultValue: "Home", displayTextArea: false, title: "Title", type: ControlType.String}, U8cqiXnj0: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerzxrtH8En5, [{family: "Poppins", style: "normal", url: "https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJXUc1NECPY.woff2", weight: "400"}, ...sharedStyle.fonts])